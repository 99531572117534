<template>
    <div class="flex center nav">
        <el-menu
            v-if="width>950"
            :default-active="default_active"
            class="el-menu-demo col8 around1 flex wrap"
            mode="horizontal"
            :menu-trigger="'hover'"
            @select="selectEvent"
            background-color="#1b76a5"
            text-color="#fff"
            active-text-color="#c19c56">
            
            <div v-for="route in router" :key="route.name">
                <div v-if="route.children&&route.children.length">
                    <el-submenu  :popper-append-to-body="true"  :index="'/'+route.path" >
                        <template slot="title" >
                        <div @click="open('/'+route.path)">{{route.meta.title}}</div>
                        </template>
                        <el-menu-item v-for="item in route.children" :key="item.name" :index="'/'+route.path+'/'+item.path">{{item.meta.title}}</el-menu-item>
                    </el-submenu> 
                </div>
                <div v-else>
                    <el-menu-item :popper-append-to-body="true"  :index="'/'+route.path" >
                        <template slot="title">{{route.meta.title}}</template>
                    </el-menu-item> 
                </div>
            </div>
            
            
        </el-menu>
        <img v-if="width<=950"  @click="isOpen = !isOpen" :src="require('../assets/menu_open.png')" class="menu_icon">
        <div v-show="width<=950&&isOpen" class="menu_vetical">
          <img  @click="isOpen = !isOpen" :src="require('../assets/menu_close.png')" class="menu_icon">
            <el-menu
            :default-active="default_active"
            class="el-menu-demo2"
            mode="vertical"
            :menu-trigger="'hover'"
            @select="selectEvent"
            background-color="rgb(14 66 132)"
            text-color="#fff"
            active-text-color="#c19c56">
            
            <div v-for="route in router" :key="route.name">
                <div v-if="route.children&&route.children.length">
                    <el-submenu  :popper-append-to-body="true"  :index="'/'+route.path" >
                        <template slot="title" >
                        <div @click="open('/'+route.path)">{{route.meta.title}}</div>
                        </template>
                        <el-menu-item v-for="item in route.children" :key="item.name" :index="'/'+route.path+'/'+item.path">{{item.meta.title}}</el-menu-item>
                    </el-submenu> 
                </div>
                <div v-else>
                    <el-menu-item :popper-append-to-body="true"  :index="'/'+route.path" >
                        <template slot="title">{{route.meta.title}}</template>
                    </el-menu-item> 
                </div>
            </div>
            
            
        </el-menu>
        </div>
    </div>
        
    
</template>
<script>
export default {
    // props:['mode'],
    props:{
        modeStr:{
            type:String,
        }
    },
    data(){
        return{
            // show:false,
            router:[],
            default_active:'news',//当前菜单
            isOpen:false,
        }
    },
    mounted(){
        console.log(this.$router)
        let data = this.$router.options.routes[1].children
        data = data.map(item=>{
            if(item.children){
                return {...item,children:item.children.filter(route=>!route.meta.ignore)}
            }else{
                return item
            }
            
        })
        console.log(data)
        // data = data[0].children.filter(item=>!item.meta.ignore)
        this.router = data
        console.log(data,this.$route)
        let default_active = ''
        if(this.$router.history.pending){
            if(this.$router.history.pending.meta.ignore){
                default_active = this.$router.history.pending.meta.path
            }else{
                default_active = this.$router.history.pending.fullPath
            }
        }else {
            if(this.$router.history.current.meta.ignore){
                default_active = this.$router.history.current.meta.path
            }else{
                default_active = this.$router.history.current.fullPath
            }
        }
        this.default_active = default_active
        console.log(this.default_active)
    },
    computed: {
        mode: function(){
            return this.modeStr
        },
        path(){//确定当前选中的菜单  
            console.log(this.$route)
            if(this.$route.meta.ignore){
                return this.$route.meta.path
            }else{
                return this.$route.path  //当前路由路径
            }
            // return this.$store.state.path.path
            
        },
        width(){
          return this.$store.state.width
        }
    },
    watch:{
        path(value){
            console.log(value)
            this.default_active = value
        },
    },
    methods:{
        goHome(){
            this.set_isOpen()
            this.$store.commit('set_path',{path:'/',query:{}})
        },
        selectEvent(index,indexPath){
            this.set_isOpen()
            console.log(index,indexPath)
            // this.show = false;
            // this.$store.commit('set_Module',indexPath[0])
            this.$store.commit('set_path',{path:index,query:{}})
        },
        open(index){
            this.set_isOpen()
            // console.log(index)
            // this.$store.commit('set_Module',index)
            this.$store.commit('set_path',{path:index,query:{}})
        },
        set_isOpen(){
          this.isOpen = false
        }
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scope>
.el-menu-demo2{
  background-color: rgb(14 66 132);
  margin-top: 43px;
}
.el-menu-demo2 .el-submenu__title{
  background-color: #0e4284!important;
}
.el-menu-demo2 .el-submenu .el-menu-item{
  background-color: #0e4284!important;
}
.el-menu-demo2 .el-submenu{
  max-width:100%;
}
.el-menu-demo2 .el-menu-item{
  max-width:100%;
}
.menu_vetical{
  position:fixed;
  z-index:999;
  background:#0e4284;
  width:100%;
  height:100vh;
  top:0;
  overflow: scroll;
}
.menu_icon{
    position: absolute;
    right: 20px;
    top: 8px;
    width: 35px;
}
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
    min-width:150px;
}
.el-menu--horizontal{
    display:flex;
}
.nav{
    width: 100%;
    background-color: #1b76a5;
    /* height: 52px; */
    overflow: hidden;
    
}
.el-menu.el-menu--horizontal{
    border-bottom:none;
}
.el-menu-item:hover{
    background:rgba(33,77,151,1)!important;
}
.el-menu-item{
    /* min-width:8%; */
    max-width:150px;
}
.el-submenu{
    /* min-width:8%; */
    max-width:150px;
}
.el-submenu__title:hover{
    background:rgba(33,77,151,1)!important;
}
</style>