<template>
  <div class="main">
    <!-- <el-tabs v-model="activeName" >
            <el-tab-pane v-for="route in tabData" :key="route.meta.title" :label="route.meta.title" :name="Module+'/'+route.path">
            </el-tab-pane>
        </el-tabs> -->
    <div v-show="hasChild" class="col8" style="margin: 0 auto">
      <el-radio-group
        v-if="width > 767"
        v-model="activeName"
        @change="changeEvent"
      >
        <el-radio-button
          v-for="route in tabData"
          :key="route.meta.title"
          :label="route.meta.title"
          :name="Module + '/' + route.path"
        ></el-radio-button>
      </el-radio-group>
      <el-dropdown v-else @command="handleCommand">
        <span class="el-dropdown-link">
          目录<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="route in tabData"
            :command="Module + '/' + route.path"
            :key="route.meta.title"
            >{{ route.meta.title }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "", //当前路由title决定
      // router:[],
      // route:{}
    };
  },
  mounted() {
    console.log("this.$route", this.$route);
    let name = "";
    if (this.$route.meta.ignore) {
      name = this.$route.meta.title;
    } else {
      name = this.$route.meta.title;
    }
    this.activeName = name;
  },
  computed: {
    Module() {
      return this.$store.state.Module;
    },
    hasChild() {
      console.log(this.Module);
      let data = this.$router.options.routes[1].children.filter(
        (item) => "/" + item.path == this.Module
      );
      console.log(data);
      if (data.length) {
        return true;
      } else {
        return false;
      }
    },
    tabData() {
      console.log(this.$router.options, this.Module, "tabData");
      let data = this.$router.options.routes[1].children.filter(
        (item) => "/" + item.path == this.Module
      );
      console.log(data, "tabData");
      return (
        (data.length &&
          data[0].children &&
          data[0].children.filter((item) => !item.meta.ignore)) ||
        []
      );
    },
    width() {
      return this.$store.state.width;
    },
    route() {
      return this.$route;
    },
  },
  watch: {
    route(value) {
      this.activeName = value.meta.title;
    },
  },
  methods: {
    changeEvent(path) {
      let pathStr =
        this.Module +
        "/" +
        this.tabData.filter((item) => item.meta.title == path)[0].path;
      console.log(path, pathStr);
      this.$store.commit("set_path", { path: pathStr, query: {} });
    },
    handleCommand(path) {
      console.log(path);
      this.$store.commit("set_path", { path: path, query: {} });
    },
  },
};
</script>
<style scoped src="@/common.css"></style>
<style scoped>
.main {
  /* background: #efefef; */
  margin-top:20px ;
  background: #ffffff;
  /* padding: 7px 0; */
  max-width: 100%;
  width: 100vw;
}
/deep/.el-radio-button__inner {
  /* border:none; */
  color: #fff;
  /* background: #efefef; */
  background: #555;
  font-size: 1rem;
  width: 8rem;
  max-width: 280px;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  /* border:none; */
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #008fd7;
  border-color: #ffffff;
  box-shadow: -1px 0 0 0 #ffffff;
}
/deep/ .el-radio-button__inner:hover {
  color: #fff;
  background-color: #008fd7;
}
/deep/ .el-dropdown {
  /* border: 1px solid #000; */
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #33d5da;
  color: #fff;
}
/deep/ .el-dropdown-link {
  font-size: 24px;
}
</style>
