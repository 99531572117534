<template>
  <div class="main_box">
    <div class="blue flex center span4" style="padding: 20px 0">
      <img v-lazy="require('../assets/logo.png')" class="logo_img" />
      <p class="m bold">福建历思司法鉴定所</p>
    </div>

    <div
      v-if="width > 893"
      class="flex center span4 start2 before_line"
      style="flex-direction: column; position: relative"
    >
      <p class="s">经福建省司法厅批准登记成立的第三方司法鉴定机构</p>
      <p class="s blue">历思有除法医精神病鉴定外的17项，其中环境全类7大项</p>
    </div>
    <div v-if="width > 893" class="flex center span3">
      <img v-lazy="require('../assets/common/top1-1.png')" class="phone_img" />
      <div class="flex column start2">
        <p
          class="s"
          style="line-height: 1rem; letter-spacing: 2px; color: #a6a6a6"
        >
          服务咨询热线
        </p>
        <p class="s blue" style="line-height: 1rem; letter-spacing: 2px">
          0592-2283132
        </p>
        <p class="s blue" style="line-height: 1rem; letter-spacing: 2px">
          400-004-8820
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    console.log(this.$store);
  },
  computed: {
    width() {
      return this.$store.state.width;
    },
  },
};
</script>
<style scoped>
.main_box {
  background: url("../assets/common/bg1-1.png");
  background-size: cover;
  min-height: 100px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.logo_img {
  width: 80px;
  height: 80px;
  padding: 0 20px;
}
.phone_img {
  width: 50px;
  height: 50px;
  padding: 0 20px;
}
.logo_box {
}
.span4 {
  width: 35%;
  /* max-width:525px; */
  /* min-width:425px; */
}
.span3 {
  width: 30%;
  max-width: 450px;
  /* min-width:374px; */
}
.before_line:before {
  content: "";
  display: block;
  width: 1px;
  height: 3rem;
  background: #d1d1d1;
  position: absolute;
  left: -9px;
}
@media screen and (max-width: 1200px) {
  .span4 {
    width: 50%;
    max-width: 750px;
  }
  .span3 {
    width: 50%;
    max-width: 750px;
  }
}
@media screen and (max-width: 873px) {
  .span4 {
    width: 100%;
    min-width: unset;
  }
  .span3 {
    width: 100%;
  }

  .start2 {
    align-items: center;
  }
}
.s {
  line-height: 2rem;
}
</style>
