<template>
  <div :id="'map' + id" class="map"></div>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const _this = this;
      console.log(this.id);
      // eslint-disable-next-line no-undef
      this.map = new BMapGL.Map("map" + this.id);
      // eslint-disable-next-line no-undef
      let point = new BMapGL.Point(118.10864844924734, 24.51786926806679);
      this.map.centerAndZoom(point, 17); // 初始化地图,
      this.map.enableScrollWheelZoom(true);
      // eslint-disable-next-line no-undef
      let marker = new BMapGL.Marker(point);
      this.map.addOverlay(marker);
      var opts = {
        width: 200, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: `<div class="title">
            福建历思司法鉴定所
        </div>`, // 信息窗口标题
        offset: {
          height: -20,
          width: 0,
        },
      };
      // eslint-disable-next-line no-undef
      var infoWindow = new BMapGL.InfoWindow(
        ` <div class="content">
                <p class="item">地址：福建省厦门市湖里区湖里大道 33号7层(西侧)</p>
                <p class="item">电话：(0592)2283132</p>
                <p class="item">电话： 400-004-8820</p>
            </div>`,
        opts
      ); // 创建信息窗口对象
      _this.map.openInfoWindow(infoWindow, point); //开启信息窗口
      marker.addEventListener("click", function () {
        _this.map.openInfoWindow(infoWindow, point); //开启信息窗口
      });
    },
  },
};
</script>
<style scoped>
.map {
  width: inherit;
  height: inherit;
  border: #d5d5d5 solid 1px;
  margin: 0 auto;
}
/deep/.title {
  color: #284c9f;
  font-size: 16px;
}
/deep/.anchorBL {
  display: none;
}
/deep/.content {
  font-size: 13px;
  letter-spacing: 1px;
}
</style>
