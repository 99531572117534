<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>

export default {
    name: 'App',
    computed:{
        path(){
            return this.$store.state.path;
        },
        route(){
            return this.$route
        }
    },
    watch:{
        path(value){//路由跳转全网监控
            console.log('path',value)
            this.$router.push({
                path:value.path,
                query:value.query 
            })
        },
        route(){ //全局路由监控
            console.log(this.$route.matched[1].path)
            this.$store.commit('set_Module',this.$route.matched[1].path)//父路由
        }

    },
    beforeDestroy(){
      window.removeEventListener('resize',this.setWidth)
    },
    mounted(){
        console.log(this.$router)
        let width = window.innerWidth;
        this.$store.commit('set_width', width)
         window.addEventListener('resize',this.setWidth) 
        // if(this.$route.matched){
        //     this.$store.commit('set_Module',this.$route.matched[1].path)//父路由
        // }
        
        this.$store.commit('set_path',{path:this.$router.history.pending&&this.$router.history.pending.fullPath||this.$router.history.current.fullPath,query:{}})//当前路径
    },
    methods:{
      setWidth(){
        console.log(window)
        let width = window.innerWidth;
        this.$store.commit('set_width', width)
      }
    }
}
</script>

<style>
@import "./common.css";
</style>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #4d4e4e; */
  /* margin-top: 60px; */
}
p{
    margin:0;
}
@media screen and ( min-width:1200px){
    html{
        font-size:17px!important;
    }
}
@media screen and ( max-width:768px){
    html{
        font-size:14px!important;
    }
}
</style>
