<template>
  <el-carousel
    :style="'position:relative;height:' + image_height + 'px'"
    indicator-position="inside"
    :autoplay="true"
  >
    <el-carousel-item v-for="(item, index) in data" :key="item">
      <img :src="item" class="Limage_img" />
      <template v-if="width > 767">
        <el-button v-show="index != 2" class="btn box_shadow" @click="go"
          >点击咨询></el-button
        >
      </template>
      <template v-else>
        <el-button
          v-show="index != 2"
          class="btn box_shadow ask_none"
          @click="go"
          >点击咨询></el-button
        >
      </template>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  data() {
    return {
      data: [
        require("../assets/common/1-1.jpg"),
        require("../assets/common/1-2.jpg"),
        require("../assets/common/1-3.jpg"),
      ],
      image_height: "",
    };
  },
  mounted() {
    const _this = this;
    window.onload = function () {
      let Limage_img_dom = document.getElementsByClassName("Limage_img")[0];
      console.log(Limage_img_dom.height);
      _this.image_height = Limage_img_dom.height;
    };
    window.addEventListener("resize", this.setHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight);
  },
  computed: {
    width() {
      return this.$store.state.width;
    },
  },
  methods: {
    setHeight() {
      let Limage_img_dom = document.getElementsByClassName("Limage_img")[0];
      this.image_height = Limage_img_dom.height;
    },
    go() {
      this.$router.push({
        path: "/connect/connect_way",
        query: {},
      });
    },
  },
};
</script>
<style>
.el-carousel__container {
  /* max-height: 800px; */
  /* min-height: 26vw; */
  height: 100%;
}
.Limage_img {
  width: 100%;
}
.btn {
  background: rgb(51, 213, 218);
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 10%;
  z-index: 2;
  right: 28%;
  border-color: rgb(51, 213, 218);
}
.btn:hover {
  background: rgb(51, 213, 218);
  color: rgb(255, 255, 255);
  border-color: rgb(51, 213, 218);
}
.ask_none {
  opacity: 0;
}
</style>
