import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import VueRouter from 'vue-router'
import router from './router.js'
import ImagePreview from './components/ImagePreview'
import connectBtn from './components/connectBtn'
import store from "./store"
import "animate.css"
import "hover.css"
import {WOW} from "wowjs"
import VueLazyload from 'vue-lazyload'  // 引入这个懒加载插件
// Vue.use(VueLazyload) // 直接使用
Vue.use(VueLazyload, { // 添加自定义选项
  preLoad: 1.3,
  error: require('./assets/error.png'), // 加载错误时候的图片
  loading: require('./assets/loading.gif'), // 加载中的图片
  attempt: 3,
  listenEvents: [ 'scroll' ],
})
Vue.prototype.$wow = WOW
Vue.mixin({
    mounted() {
        this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        const wow = new this.$wow({
            live: false
        })
        wow.init()
        })
    },
})
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.component('ImagePreview',ImagePreview)
Vue.component('ConnectBtn',connectBtn)

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});