<template>
    <div style="margin-bottom:2rem;">
        <el-button @click="go" type="primary">立即咨询</el-button>
    </div>
</template>
<script>
export default {
    methods:{
        go(){
            this.$router.push({
                path:'/connect/connect_way',
                query:{}
            })
        }
    }
}
</script>