<template>
    <div id="main">
        <Logo></Logo>
        <Nav ></Nav>
        <Limage></Limage> 
        <div id="main_content">
            
            <Tab></Tab>
            <router-view></router-view>
        </div>
        <Footer></Footer>
        <!-- <Side></Side> -->
    </div>
</template>
<script>
import Nav from "@/components/Nav";
import Logo from "@/components/Logo";
import Limage from "@/components/Limage";
import Footer from "@/components/Footer";
import Tab from "@/components/Tab";
// import Side from "@/components/Side";
export default {
    components:{
        Nav,
        Logo,
        Limage,
        Footer,
        // Side,
        Tab
    },
    data(){
        return {
            // hasChild:true,
        }
    },
    computed:{
      width(){
        return this.$store.state.width
      }
    },
    created(){
        console.log(this.$router)
    }
    
}
</script>
<style scoped>
#main{
    /* max-width:1500px; */
    width:100%;
    margin:0 auto;
    height:100vh;
}
#main_content{
    max-width:1500px;
    margin:0 auto;
}
</style>