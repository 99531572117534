<template>
  <div class="box" style="margin: 0 auto">
    <div class="flex center">
      <div class="main flex center" style="flex-wrap: wrap">
        <div class="col5" style="padding: 3rem 0">
          <div class="span8">
            <div class="top_box flex wrap col8">
              <p
                class="link"
                :style="
                  index != 3 && index != data.length - 1
                    ? 'border-right:1px solid '
                    : ''
                "
                v-for="(item, index) in data"
                :key="item.path"
                @click="go(item)"
              >
                {{ item.meta.title }}
              </p>
            </div>
            <div class="mid_box">
              <p>公司地址：福建省厦门市湖里区湖里大道 33号7层(西侧)</p>
              <p>
                服务热线: 0592-2283132&nbsp; &nbsp; &nbsp; &nbsp; 400-004-8820
              </p>
              <p>邮箱:Lisijianding@163.com 传真：0592-2283932</p>
            </div>
            <div class="fot_box flex wrap">
              <div class="span3 flex center column">
                <div class="bd">
                  <div
                    class="flex center"
                    style="
                      width: 88%;
                      width: 88%;
                      padding: 5%;
                      position: relative;
                    "
                  >
                    <img
                      v-lazy="require('../assets/common/footer2-1.png')"
                      class="inherit"
                    />
                  </div>
                </div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="微信公众号"
                  placement="top"
                >
                  <p class="label">微信公众号</p>
                </el-tooltip>
              </div>

              <div class="span3 flex center column">
                <div class="bd">
                  <div
                    class="flex center"
                    style="
                      width: 88%;
                      width: 88%;
                      padding: 5%;
                      position: relative;
                    "
                  >
                    <img
                      v-lazy="require('../assets/common/footer2-2.png')"
                      class="inherit"
                    />
                  </div>
                </div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="抖音号"
                  placement="top"
                >
                  <p class="label">抖音号</p>
                </el-tooltip>
              </div>

              <!-- <div  class="span3 flex center column">
                                <div class="bd ">
                                    <div class="flex center" style="width:88%;width: 88%;padding: 5%;position:relative">
                                        <img v-lazy="require('../assets/common/footer2-3.png')" class="inherit">
                                        
                                    </div>
                                </div>
                                <el-tooltip class="item" effect="dark" content="中国司法鉴定门户网" placement="top">
                                    <p class="label">中国司法鉴定门户网</p>
                                </el-tooltip>
                                
                            </div> -->
            </div>
          </div>
        </div>
        <div class="col5 flex center" style="height: 300px">
          <div class="flex center" style="height: 90%; width: 90%">
            <Map :id="1"></Map>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_box">
      Copyright © 福建历思鉴定所,闽ICP备2021019645号-1, All Rights Reserved。
    </div>
  </div>
</template>
<script>
import Map from "@/components/Map.vue";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Map,
  },
  created() {
    this.data = this.$router.options.routes[1].children;
  },
  methods: {
    go(item) {
      let pathStr = "/" + item.path;

      this.$store.commit("set_path", { path: pathStr, query: {} });
      this.$store.commit("set_Module", item.path);
    },
  },
};
</script>
<style scoped>
.link {
  width: 24%;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}
.link:hover {
  color: #fff;
}
.box {
  color: #aaaaaa;
  background: url("../assets/common/footer1-1.png");
  background-position: right;
}
.fot_box {
  /* width:67%; */
  /* min-width:300px; */
}
.footer_box {
  background: #3f3f3f;
  padding: 10px 0;
  color: #fff;
  font-size: 0.5rem;
}
.mid_box {
  text-align: left;
  letter-spacing: 1px;
  font-size: 0.9rem;
  padding: 2rem 0;
  line-height: 1.5rem;
}
.bd {
  background: url("../assets/common/footer1-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 10vw;
  max-width: 100px;
  min-width: 88px;
}
.label {
  margin-top: 12px;
  width: 87%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.span8 {
  width: 80%;
}
.span3 {
  width: 33.3%;
  min-width: 100px;
}
@media screen and (max-width: 1448px) {
  .span8 {
    width: 100%;
  }
  .span3 {
  }
}
@media screen and (max-width: 305px) {
  .span3 {
    width: 50%;
  }
}
@media screen and (max-width: 225px) {
  .span3 {
    width: 100%;
  }
}
</style>
