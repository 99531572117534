import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

let store = new Vuex.Store({
    state:{
        Module: '',//当前模块 如/goin
        width:'',//宽度
        path:{path:'',query:{}},//当前路径

    },
    mutations:{
        set_Module(state, payload){ 
            state.Module = payload
        },
        set_width(state, payload){
            state.width = payload
        },
        set_path(state, payload){
            state.path = payload
        }
    }
})
export default store